html, body, #root, canvas {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#buttons-area {
  position: fixed;
  float: right;
  top: 0;
  right: 0;
  margin-top: 5px;
  margin-right: 5px;
  z-index: 1;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}